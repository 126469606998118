import React from "react";
// reactstrap components
import { Col, Row } from "reactstrap";

const FullPageLoader: React.FC = () => {

    return (
        <Row className="position-fixed fixed-top bg-white h-100 ion-align-items-center ion-justify-content-center">
            <Col className="col-auto">
                    <img className="img-fluid" src={require('../../assets/img/icons/common/loading.gif')} alt="loading" style={{ transform: 'scale(1)' }} />
            </Col>
        </Row>
    )
}

export default FullPageLoader;