import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonPage, IonTitle, IonToast, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { arrowBackOutline, cart, close, refreshOutline, search } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import EstablishmentList from '../../components/EstablishmentCards/general';
import Addons from '../../components/MenuCards/addons';
import FeaturedMenu from '../../components/MenuCards/featured';
import MenuList from '../../components/MenuCards/general';
import FeaturedSkeletonText from '../../components/skeleton_text/featured_establishment';
import GeneralSkeletonText from '../../components/skeleton_text/general_establishment';
import axios from '../../helpers/axiosInterceptor';

const Category: React.FC = (props: any) => {

	const [productsList, setProductsList]: any[] = useState([]);
	const [details, setDetails] = useState({} as any);
	const [featuredProducts, setFeaturedProducts] = useState([]);
	const [category, setCategory] = useState({
		id: props.match.params.id,
		name: props.match.params.name,
		image: props.match.params.image.replace(new RegExp('%2F', 'g'), '/')
	});
	const [query, setQuery] = useState("");
	const [load, setLoad] = useState(false);
	const [status, setStatus] = useState({
		searching: false,
		loaded: false,
		error: ''
	});
	const limitInit = 15;
    const [limit, setLimit] = useState(limitInit);
	const [disableInfiniteScroll, setDisableInfiniteScroll] = 
        useState<boolean>(false);
	const [idsIgnore, setIdsIgnore]: any[] = useState([]);
	const [showToast, setShowToast] = useState(false);
	const [msgToast, setMsgToast] = useState('');

	//Selectors
	const getProductState = store => store.cart.products

	// Get index
	const getAllProducts = (store) => {
		const products = getProductState(store) ?? []
		let total = 0, addons_cost = 0
		products.map((product) => {
			product.addons_product.map((d) => {
				d.addons.map((a) => addons_cost += parseInt(a.price))
			})
			return total += (product.count * product.price) + addons_cost
		})
		total = Number(total.toFixed(2));
		return { count: products.length, total }
	}

	//Redux Hooks
	const basket_establishment_id = useSelector((state: RootStateOrAny) => state.cart.establishment_id);
	const basket = useSelector((state: RootStateOrAny) => getAllProducts(state));
	const currency_symbol = useSelector((state: RootStateOrAny) => state.auth.currency_symbol);
	const auth = useSelector((state: RootStateOrAny) => state.auth)

	useIonViewWillEnter(() => {
		setStatus({ ...status, error: '', searching: false });
		onSearch(null);
	}, []);

	const refreshError = () => {
		if (status.error != '' && productsList.length == 0) {
			onSearch(null);
		}
	}

	const onSearch = async (e, $infiniteScrollEvent?) => {
        let q = '';
        let productL = productsList;

        if (e) {
            q = e.target.value;
            productL = [];
		    setQuery(q);
            setStatus({ ...status, error: '', searching: true });
        } else {
            q = query;
            setStatus({ ...status, error: '', searching: false });
        }
		
		if (q.trim().length < 3 || status.searching) {
            //return;
        }

		// Verificar autenticação
        let url = '/customer/establishment/search/1';
        if (auth.user) {
            url = '/establishment/search/1';
        } else {
            // Local Storage
            const storage = JSON.parse(localStorage.getItem('persist:storage') as any)
            if (storage) {
                if (JSON.parse(storage.auth).auth_token) {
                    url = '/establishment/search/1';
                }
            }
        }

		await axios.post(url, {search: q,  limit: limit, idsIgnore: idsIgnore, categories: [category.id]})
			.then(res => {
                const products: any[] = [...productL, ...res.data.establishment.products] as any[];
				setDetails(res.data.establishment);
				setProductsList(products);
                setFeaturedProducts(res.data.establishment.featureds);

                if (res.data.establishment.products.length == 0) {
                    setDisableInfiniteScroll(true);
                }

                const ids = products.map(function (item) {
                    return item.id;
                });
                setIdsIgnore(ids);

                setLimit(limit + limitInit);
			})
			.catch(err => {
				setStatus({ ...status, loaded: true, error: 'Falha ao carregar itens!', searching: false });
                setMsgToast("Falha ao carregar itens!");
                setShowToast(true);
			});

        setStatus({ ...status, searching: false });
		setLoad(true);

        if ($infiniteScrollEvent) {
            ($infiniteScrollEvent.target).complete();
        }
        
	}


	return (
		<IonPage>

			<IonHeader className="bg-white ion-no-border border-bottom">
				<IonToolbar color="white">
					<IonButtons slot="primary">
						<IonButton onClick={() => setQuery('')}>
							<IonIcon icon={close} className="text-muted" />
						</IonButton>
					</IonButtons>
					<IonButtons slot="start">
						<IonButton onClick={() => props.history.goBack()}>
							<IonIcon icon={arrowBackOutline} className="mr-2 align-text-top text-default"/>
						</IonButton>
						
						<IonButton>
							<IonIcon icon={search} className="text-muted" />
						</IonButton>
					</IonButtons>

					<IonInput debounce={1500} onIonChange={onSearch} value={query} placeholder="Pesquisar Produtos" className="font-weight-bold text-default">
					</IonInput>
				</IonToolbar>
			</IonHeader>


			<IonContent fullscreen onClick={() => refreshError()}>
				<IonToast
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={msgToast}
					duration={3000}
				/>

				{!query && <div className="p-3 text-center" style={{height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center',backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.85)), url('${category.image}')`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
					<h4 className="font-weight-light text-white">{category.name}</h4>
				</div>}

				{status.error != '' && productsList.length == 0  ? (
                        <div className="text-center px-3 pt-3">
                            <p className="m-0 text-muted" style={{verticalAlign: 'midle'}}>Toque para tentar novamente <IonIcon icon={refreshOutline} /></p>
                            
                        </div>
                    ) : (
                        <Container className="p-0 mb-5">
                            {(featuredProducts.length > 0 && !query) && (<Container fluid={true} className="my-3 p-0">
                            <h5 className="font-weight-bold px-3">Produtos em Destaque</h5>
                            <Row className="my-2">
                                <Col lg="5">
                                    {load ? (
                                        (featuredProducts.length > 0) ? <FeaturedMenu menuList={featuredProducts} establishment_id={details.id} />
                                            : (
                                                <div className="bg-white shadow-sm py-4 m-3 text-center">
                                                Nenhum produto em destaque disponível
                                                </div>
                                            )
                                    ) : <FeaturedSkeletonText />}
                                </Col>
                            </Row>
                        </Container>)}

                        <Container fluid={true} className="mt-3">
                            { load && <h5 className="font-weight-bold">Produtos</h5> }
                            <Row>
                                <Col lg="5">
                                    {load && !status.searching ? (
                                        (productsList.length > 0) ? <MenuList menuList={productsList} establishment_id={details.id} />
                                            : (
                                                <div className="bg-white shadow-sm py-4 my-2 w-100 text-center">
                                                    Nenhum produto disponível
                                                </div>
                                            )
                                    ) : [...Array(4)].map((e, i) => (<GeneralSkeletonText key={i} />))}
                                </Col>
                            </Row>
                        </Container>

                        {
                            (load && details.id === basket_establishment_id && basket.count > 0) && (
                                <Link className="row fixed-bottom w-100 bg-primary p-3 m-0 text-white" to="/cart">
                                    <Col xs="8" className="p-0">
                                        {basket.count} {basket.count == 1 ? 'Item' : 'Itens'} |  {currency_symbol} {basket.total}
                                    </Col>
                                    <Col className="text-right p-0">
                                        Carrinho
                                        <IonIcon icon={cart} className="ml-2 align-text-top text-white" />
                                    </Col>
                                </Link>
                            )
                        }

                        <Addons />
                    {(disableInfiniteScroll && <div className="pb-3"></div>)}
                    <IonInfiniteScroll className="pb-3" threshold="100px"
                        disabled={disableInfiniteScroll}
                        onIonInfinite={(e: CustomEvent<void>) => onSearch(null, e)}>
                        <IonInfiniteScrollContent
                            loadingText="">
                        </IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                        </Container>
                    )
                }
				
			</IonContent>


		</IonPage>
	);
};

export default Category;
