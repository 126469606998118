import React, { useEffect } from "react";
// reactstrap components
import { Col, Row } from "reactstrap";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import actions from '../../actions/cart'

interface SubBillProps {
	currency_symbol?: string,
	sub_total?: any,
	establishment_charge?: number,
	delivery_charge?: number,
	taxes?: number,
	charges?: any,
	coupon?: any
}

const CartBillings: React.FC<SubBillProps> = ({ currency_symbol, sub_total, charges, coupon }) => {
	sub_total = Number(sub_total.toFixed(2));
	const dispatch = useDispatch();
	const cart = useSelector((state: RootStateOrAny) => state.cart);

	function subTotal() {
		const subTotal = Number((Number(sub_total) + Number(charges.establishment_charges) + Number(charges.delivery_charges)).toFixed(2));
		return subTotal
	}

	function tax() {
		const tax = (subTotal() - coupons()) * (Number(charges.taxes) / 100)
		return Math.round(tax * 100) / 100
	}

	function coupons() {
		let sub_total = subTotal()

		if (coupon.id) {
			if (coupon.discount_type === 'Porcentagem')
				sub_total = sub_total * ((coupon.discount) / 100)
			else if (coupon.discount_type === 'Fixo')
				sub_total = coupon.discount

			return Math.round(sub_total * 100) / 100
		}
		return 0
	}

	const grand_total = () => {
		let coupon = coupons()
		let sub_total = subTotal() - coupon

		let total = tax() + sub_total;
		total = Math.round(total * 100) / 100;
		cart.total = total;
		
		return total;
	}

	const Taxes = () => <>{tax()}</>
	const Coupon = () => <>{coupons()}</>
	const GrandTotal = () => <>{grand_total()}</>



	useEffect(() => {
		dispatch(actions.grand_total(grand_total()))
	}, [coupon])

	return (
		<>
			<Row className="flex-row flex-wrap shadow-0 pt-2 px-3 m-0 border-0">
				<Col className="p-0">
					<h6 className="my-0 font-weight-light item-name">Item Sub-Total </h6>
					<div className="d-flex align-items-center justify-content-between text-muted pr-2 pt-1">

					</div>
				</Col>
				<Col xs="4" className="p-0">
					<span className="text-dark text-right font-weight-light d-block">{currency_symbol} {sub_total}</span>
				</Col>
			</Row>
			{
				(charges.establishment_charges != null && Number(charges.establishment_charges) !== 0) && (
					<Row className="flex-row flex-wrap shadow-0 pt-2 px-3 m-0 border-0">
						<Col className="p-0">
							<h6 className="my-0 font-weight-light item-name">Taxa de embalagem do estabelecimento</h6>
							<div className="d-flex align-items-center justify-content-between text-muted pr-2 pt-1">

							</div>
						</Col>
						<Col xs="4" className="p-0">
							<span className="text-dark text-right font-weight-light d-block">{currency_symbol} {charges.establishment_charges}</span>
						</Col>
					</Row>
				)
			}
			{
				(charges.delivery_charges != null && Number(charges.delivery_charges) !== 0) && (
					<Row className="flex-row flex-wrap shadow-0 pt-2 px-3 m-0 border-0">
						<Col className="p-0">
							<h6 className="my-0 font-weight-light item-name">Taxa de Entrega</h6>
							<div className="d-flex align-items-center justify-content-between text-muted pr-2 pt-1">

							</div>
						</Col>
						<Col xs="4" className="p-0">
							<span className="text-dark text-right font-weight-light d-block">{currency_symbol} {charges.delivery_charges}</span>
						</Col>
					</Row>
				)
			}
			{
				(coupon.discount) && (
					<Row className="flex-row flex-wrap shadow-0 pt-2 px-3 m-0 border-0">
						<Col className="p-0">
							<h6 className="my-0 font-weight-light text-success item-name">Desconto de Cupom</h6>
							<div className="d-flex align-items-center justify-content-between text-muted pr-2 pt-1">

							</div>
						</Col>
						<Col xs="4" className="p-0">
							<span className="text-success text-right font-weight-light d-block">(-) {currency_symbol} <Coupon /></span>
						</Col>
					</Row>
				)
			}
			{
				(charges.taxes != null && Number(charges.taxes) !== 0) && (
					<Row className="flex-row flex-wrap shadow-0 pt-2 px-3 m-0 border-0">
						<Col className="p-0">
							<h6 className="my-0 font-weight-light item-name">Taxas ({charges.taxes}%)</h6>
							<div className="d-flex align-items-center justify-content-between text-muted pr-2 pt-1">

							</div>
						</Col>
						<Col xs="4" className="p-0">
							<span className="text-dark text-right font-weight-light d-block">{currency_symbol} <Taxes /></span>
						</Col>
					</Row>
				)
			}
			<Row className="flex-row flex-wrap shadow pt-2 px-3 mt-2 mb-0 mx-0 border-top">
				<Col className="p-0">
					<h5 className="my-0 font-weight-bold">Total</h5>
					<div className="d-flex align-items-center justify-content-between text-muted pr-2 pt-1">

					</div>
				</Col>
				<Col xs="4" className="p-0">
					<h5 className="text-dark text-right font-weight-bold pr-0">{currency_symbol} <GrandTotal /></h5>
				</Col>
			</Row>
		</>
	)
};

export default CartBillings;