import { IonContent, IonFooter, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, IonToast  } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import loadScript from '../../helpers/loadScript';
import actions from '../../actions/cart';
import axios from '../../helpers/axiosInterceptor';
import CreditCard from './CreditCard';
import { Link } from 'react-router-dom';
import { Stripe } from '@awesome-cordova-plugins/stripe';

const Payment: React.FC = (props: any) => {

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const cart = useSelector((state: RootStateOrAny) => state.cart);
  const total = useSelector((state: RootStateOrAny) => state.cart.total);
  const payment_method = useSelector((state: RootStateOrAny) => state.cart.method);
  const creditCard = useSelector((state: RootStateOrAny) => state.cart.creditCard);
	const currency_symbol = useSelector((state: RootStateOrAny) => state.auth.currency_symbol);

  const [showToast, setShowToast] = useState(false);
  const [msgToast, setMsgToast] = useState('');

  const dispatch = useDispatch();
  const default_address = useSelector((state: RootStateOrAny) => state.auth.default_address);

  useEffect(() => {
    loadScript('https://checkout.razorpay.com/v1/checkout.js', 'razorpay')

    // Stripe
    /*axios.get('/settings/stripe').then((res) => {
			const apiKey = res.data.stripe_api_key;
      Stripe.setPublishableKey(apiKey);
		})*/

    // Cartão de Crédito
    let cartForm = cart;
    cartForm.method = 'pg_card';
    dispatch(actions.place_order_add(cartForm, props.history));
  }, [])

  const finish_order = async () => {
    let cartForm = JSON.parse(JSON.stringify(cart));
    /*const limit_min: number = cart.limit_min_payment ? Number(cart.limit_min_payment) : 50;

    if (limit_min > cart.total) {
      setMsgToast(`Limite minimo de compra é ${currency_symbol} ${limit_min}`);
      setShowToast(true);
      return;
    }*/

    

    if (cart.method === 'pg_card') {
      try {
        const expiryArray = String(cartForm.creditCard.expiry).split('/');
        const objCard = {
          number: cartForm.creditCard.number,
          exp_month: expiryArray[0],
          exp_year: expiryArray[1],
          cvc: cartForm.creditCard.cvc,
          name: cartForm.creditCard.name,
          currency: 'usd'
        };
        const result = await axios.post('/stripe/token', {content: toBase64(JSON.stringify(objCard))});
        cartForm.tokenStripe = result.data.token.id;
        delete cartForm.creditCard;
      } catch (error) {
        console.error(error);
        setMsgToast("Falha ao processar pedido!");
        setShowToast(true);
        return;
      }
    }
    
    if (cart.method === 'pg_cod' && cart.need_change && !cart.change_to) {
      setMsgToast("Preencha o campo 'Troco para'");
      setShowToast(true);
      return;
    }
    
    const response = await dispatch(actions.place_order(cartForm, props.history));
    if (!response) {
      setMsgToast("Falha ao processar pagamento!");
      setShowToast(true);
    } else {
      //setMsgToast("Pedido processado com sucesso!");
      //setShowToast(true);
    }
  }

  function toBase64(text) {
    return Buffer.from(text).toString('base64');
  }

  return (
    <IonPage>
       <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={msgToast}
        duration={3000}
      />
      <IonHeader className="bg-white ion-no-border border-bottom">
        <IonToolbar color="white">
          <IonTitle className="font-weight-bold">

            <IonIcon icon={arrowBackOutline} className="mr-2 align-text-top text-dark"
              onClick={() => props.history.goBack()}
            />
            Pagamento
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      { cart.total && (
        <IonContent fullscreen>
          <Container fluid={true} className="m-0 bg-white shadow py-3">
            <h6 className="font-weight-bold my-0 text-center text-uppercase">Valor a pagar</h6>
            <h2 className="font-weight-bold my-0 text-center">{(currency_symbol+total).toString()}</h2>
          </Container>

          <Container>
            <CreditCard/>
          </Container>

          {/*<Container fluid={true} className="my-4">
            <h5 className="font-weight-bold my-0 text-center">Selecione o método de pagamento:</h5>
            <Row className="my-0">
              <Col lg="5">
                <PaymentMethods history={props.history} />
              </Col>
            </Row>
            <Button className="position-fixed fixed-bottom w-100 bg-success p-3 m-0 text-white" onClick={toggle}>Place the order</Button>
          </Container>*/}

          <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={true} style={{ top: '10%' }}>
            <ModalHeader toggle={toggle}>Order Info</ModalHeader>
            <ModalBody className="p-0">
              <h4 className="font-weight-bold my-0 text-center">
                {/* <IonIcon icon={closeCircleOutline} className="mt-2 text-warning" /> */}
                {/* <IonIcon icon={checkmarkCircleOutline} className="mt-2 text-warning" /> */}
              </h4>
              <Row>
                <Col xs="8" className="offset-2 px-0 pt-3">
                  <img src={require('../../assets/img/icons/common/payment-success.svg')} alt="payment success" />
                </Col>
              </Row>
              <h5 className="font-weight-bold my-0 text-center text-default">Your order placed successfully</h5>
            </ModalBody>
            <ModalFooter className="justify-content-center">
              <Button block color="primary" href="/dashboard/orders">Go to Order</Button>
            </ModalFooter>
          </Modal>


        </IonContent>
      )
      }
      
      {payment_method && (<IonFooter className="bg-white">
        <Row className="m-0 px-3 pt-3">
									<Col className="p-0">
										<h6 className="font-weight-bold">ENDEREÇO DA FATURA</h6>
									</Col>
									<Col xs="3" className="p-0">
										<Link to="/address">
											<Button color="warning" size="sm" outline type="button">
												ALTERAR
              				</Button>
										</Link>
									</Col>
								</Row>
								<Row className="m-0 py-0 px-3">
									<h6 className="font-weight-light text-capitalize">{(default_address && (<><b className="font-weight-bold"> {default_address.label} - </b> {default_address.full_address}</>)) ?? 'Add new address'}</h6>
										</Row>
          <IonToolbar className="m-0">
          {creditCard && <Button
												block
												className="rounded fixed-bottom position-fixed h-100"
												color="success"
												size="lg"
												type="button"
												onClick={finish_order}
                        
											>
												Finalizar Pedido
											</Button>}
          </IonToolbar>
      </IonFooter>)}
    </IonPage >
  );
};

export default Payment;
