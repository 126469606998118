import { Plugins } from '@capacitor/core';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import React from 'react';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { useDispatch } from "react-redux";

import actions from '../../actions/auth'
import axios from '../../helpers/axiosInterceptor';
import { alertActions } from '../../actions/alert';
import { countryList } from '../../helpers/constants';

const AddAddress: React.FC = (props: any) => {

	const [userAddress, setUserAddress] = React.useState({
		street: '',
		city: '',
		state: '',
		postal_code: '',
		country_code: '',
		label: '',
	});

	const dispatch = useDispatch();

	const submitAddress = () => {
		const { street, city, state, postal_code, country_code, label } = userAddress;
		if (street.trim() === '' || city.trim() === '' || state.trim() === ''
			|| postal_code.trim() === '' || country_code.trim() === '' || label.trim() === ''
		) {
			return;
		}
			

		axios.post(`/user/address`, { street, city, state, postal_code, country_code, label })
			.then(res => {
				const data = res.data
				const filterCountry = countryList.filter(cl => cl.value == data.country_code);
				const country = filterCountry.length > 0 ? `, ${filterCountry[0].label}` : '';
				dispatch(alertActions.page_loader(true));
				dispatch(actions.set_address(
					{ 
						id: data.id,
						label: data.label,
						full_address: `${data.street}, ${data.city} - ${data.state}${country}`
					}));

				if (props.history.location.state && props.location.state.redirectTo)
						props.history.push(props.location.state.redirectTo)
					else
						props.history.goBack();

				setTimeout(() => {
					dispatch(alertActions.page_loader(false));
				}, 150)
			})
			.catch(err => {
				console.log(err)
			})
	}

	

	return (
		<IonPage>
			<IonHeader className="bg-white ion-no-border border-bottom">
				<IonToolbar color="white">
					<IonButtons slot="start">
						<IonButton onClick={() => props.history.goBack()}>
							<IonIcon icon={arrowBackOutline} className="mr-2 align-text-top text-dark" />
						</IonButton>
					</IonButtons>
					<IonTitle className="p-0">
						<small className="font-weight-bold">Novo Endereço</small>
					</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
			<Form>
				<FormGroup className="m-0 border-bottom p-3 bg-white">
					<h6 className="m-0 font-weight-bold item-name">Nome do Local <span className="text-warning">*</span></h6>
					<Input placeholder="Casa, Escritório, Trabalho..." className="border-0 shadow-0 p-0" type="text" onChange={(e) => { setUserAddress({ ...userAddress, label: e.target.value }) }} value={userAddress.label} />
				</FormGroup>
				<FormGroup className="m-0 border-bottom p-3 bg-white">
					<h6 className="m-0 font-weight-bold item-name">Endereço Completo<span className="text-warning">*</span> </h6>
					<Input placeholder='' className="border-0 shadow-0 p-0" type="text" onChange={(e) => { setUserAddress({ ...userAddress, street: e.target.value }) }} value={userAddress.street} />
				</FormGroup>
				<FormGroup className="m-0 border-bottom p-3 bg-white">
					<h6 className="m-0 font-weight-bold item-name">Código Postal <span className="text-warning">*</span> </h6>
					<Input placeholder='' className="border-0 shadow-0 p-0" type="number" onChange={(e) => { setUserAddress({ ...userAddress, postal_code: e.target.value }) }} value={userAddress.postal_code} />
				</FormGroup>
				<FormGroup className="m-0 border-bottom p-3 bg-white">
					<h6 className="m-0 font-weight-bold item-name">Cidade <span className="text-warning">*</span> </h6>
					<Input placeholder='' className="border-0 shadow-0 p-0" type="text" onChange={(e) => { setUserAddress({ ...userAddress, city: e.target.value }) }} value={userAddress.city} />
				</FormGroup>
				<FormGroup className="m-0 border-bottom p-3 bg-white">
					<h6 className="m-0 font-weight-bold item-name">Estado <span className="text-warning">*</span> </h6>
					<Input placeholder='' className="border-0 shadow-0 p-0" type="text" onChange={(e) => { setUserAddress({ ...userAddress, state: e.target.value }) }} value={userAddress.state} />
				</FormGroup>
				<FormGroup className="m-0 border-bottom p-3 bg-white">
					<h6 className="m-0 font-weight-bold item-name">País <span className="text-warning">*</span> </h6>
					<Input
						className="border-0 shadow-0 p-0"
						type="select"
						onChange={(e) => { setUserAddress({ ...userAddress, country_code: e.target.value }) }} value={userAddress.country_code}
					>
						{
							countryList.map(el => <option value={el.value} key={el.label}> {el.label} </option>)
						}
					</Input>
				</FormGroup>
			</Form>
			<Button
				block
				className="rounded fixed-bottom position-fixed"
				color="warning"
				size="lg"
				type="button"
				onClick={submitAddress}
			>
				Adicionar Endereço
			</Button>

			</IonContent>
		</IonPage >
	);
};

export default AddAddress;