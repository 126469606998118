import { IonPage } from '@ionic/react';
import React, { useState } from 'react';

const Intro: React.FC = (props: any) => {

  const [isMobileActive, setIsMobileActive] = useState(false);

  return (
    <IonPage>
      <div className='awake h-100 w-100 d-flex' style={{background: '#f8f8f8'}} id="phone_1">
        <iframe style={{maxWidth: '700px', margin: '0 auto'}} className='h-100 w-100' src={window.location.protocol + '//' + window.location.host} id="app_frame" title="app_frame"></iframe>
      </div>


    </IonPage>
  );
};

export default Intro;
