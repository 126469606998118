import { IonButton, IonInfiniteScroll, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonPage, IonTitle, IonToolbar, IonInfiniteScrollContent, IonToast, useIonViewWillEnter } from '@ionic/react';
import { basketOutline, close, cart, chevronDown, locateOutline, locationOutline, notificationsOutline, search, star, refreshOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Badge, Container, Row } from 'reactstrap';
import Categories from '../../components/EstablishmentCards/categories';
import FeaturedEstablishment from '../../components/EstablishmentCards/featured';
import EstablishmentList from '../../components/EstablishmentCards/general';
import Addons from '../../components/MenuCards/addons';
import FeaturedMenu from '../../components/MenuCards/featured';
import MenuList from '../../components/MenuCards/general';
import FeaturedSkeletonText from '../../components/skeleton_text/featured_establishment';
import GeneralSkeletonText from '../../components/skeleton_text/general_establishment';
import axios from '../../helpers/axiosInterceptor';
import actions from '../../actions/auth';


const Home: React.FC = (props: any) => {

  const [hide, setHide] = React.useState(false);
  const default_address = useSelector((state: RootStateOrAny) => state.auth.default_address);
  const [hasNotification, setHasNotification] = useState(false);

  const [details, setDetails] = useState({} as any);
  const [address, setAddress] = useState("");
  const [productsList, setProductsList]: any[] = useState([]);
  const [categories, setCategories]: any[] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [load, setLoad] = useState(false);
  const limitInit = 15;
  const [limit, setLimit] = useState(limitInit);
  const [idsIgnore, setIdsIgnore]: any[] = useState([]);

  const [status, setStatus] = useState({
		searching: false,
		loaded: false,
		error: ''
	});
  const [query, setQuery] = useState("");

  const [disableInfiniteScroll, setDisableInfiniteScroll] = 
        useState<boolean>(false);

const [showToast, setShowToast] = useState(false);
const [msgToast, setMsgToast] = useState('');

  //Selectors
  const getProductState = store => store.cart.products

  // Get index
  const getAllProducts = (store) => {
      const products = getProductState(store) ?? []
      let total = 0, addons_cost = 0
      products.map((product) => {
          product.addons_product.map((d) => {
              d.addons.map((a) => addons_cost += parseInt(a.price))
          })
          return total += (product.count * product.price) + addons_cost
      })
      total = Number(total.toFixed(2));
      return { count: products.length, total }
  }

  //Redux Hooks
  const basket_establishment_id = useSelector((state: RootStateOrAny) => state.cart.establishment_id);
  const basket = useSelector((state: RootStateOrAny) => getAllProducts(state));
  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const currency_symbol = useSelector((state: RootStateOrAny) => state.auth.currency_symbol);

  const dispatch = useDispatch();

  const hideBar = (e: any) => {
      console.log(e.scrollTop)
  }

  useIonViewWillEnter(() => {
    setStatus({ ...status, error: '', searching: false });
      init();
  }, [auth]);

  const init = () => {
    setLoad(false);
    setStatus({ ...status, error: '', searching: false });

    // Verificar autenticação
    let url = '/customer/establishment/1';
    if (auth.user) {
        url = '/establishment/1';
    } else {
        // Local Storage
        const storage = JSON.parse(localStorage.getItem('persist:storage') as any)
        if (storage) {
            if (JSON.parse(storage.auth).auth_token) {
                url = '/establishment/1';
            }
        }
    }

    window.addEventListener('ion-content', hideBar);
    axios.post(url, {limit: limit})
        .then(async res => {
            const data = res.data
            setCategories(data.categories);
            setDetails(data.establishment);
            if (!currency_symbol) {
                dispatch(actions.set_currency({currency_symbol: data.establishment.currency_symbol}));
            }
            await setProductsList(data.establishment.products);
            const ids = data.establishment.products.map(function (e) {
                return e.id;
            });
            setIdsIgnore(ids);

            setFeaturedProducts(data.establishment.featureds);

            if (data.notifications > 0)
                  setHasNotification(true)

            setLoad(true);

            const address = data.establishment.addresses[0]
            const add = address.street + ', ' + address.city + ', ' + address.postal_code
            setAddress(add)
            
        })
        .catch(err => {
            console.log(err)
            setLoad(true)
            setStatus({ ...status, error: 'Falha ao carregar itens!', searching: false });
            setMsgToast("Falha ao carregar itens!");
            setShowToast(true);
        })
  }

  const refreshError = () => {
      if (status.error != '' && productsList.length == 0) {
          init();
      }
  }

  const onSearch = async (e, $infiniteScrollEvent?) => {
        let q = '';
        let productL = productsList;
        if (e) {
            q = e.target.value;
            productL = [];
		    setQuery(q);
            setStatus({ ...status, error: '', searching: true });
        } else {
            q = query;
            setStatus({ ...status, error: '', searching: false });
        }
		
		if (q.trim().length < 3 || status.searching) {
            //return;
        }

        // Verificar autenticação
        let url = '/customer/establishment/search/1';
        if (auth.user) {
            url = '/establishment/search/1';
        } else {
            // Local Storage
            const storage = JSON.parse(localStorage.getItem('persist:storage') as any)
            if (storage) {
                if (JSON.parse(storage.auth).auth_token) {
                    url = '/establishment/search/1';
                }
            }
        }

		await axios.post(url, {search: q,  limit: limit, idsIgnore: idsIgnore})
			.then(res => {
                const products: any[] = [...productL, ...res.data.establishment.products] as any[];
                setCategories(res.data.categories);
				setProductsList(products);
                setFeaturedProducts(res.data.establishment.featureds);

                if (res.data.establishment.products.length == 0) {
                    setDisableInfiniteScroll(true);
                }

                const ids = products.map(function (item) {
                    return item.id;
                });
                setIdsIgnore(ids);

                setLimit(limit + limitInit);

                if (res.data.notifications > 0)
                    setHasNotification(true)
			})
			.catch(err => {
				setStatus({ ...status, loaded: true, error: 'Falha ao carregar itens!', searching: false });
                setMsgToast("Falha ao carregar itens!");
                setShowToast(true);
			});

        setStatus({ ...status, searching: false });

        if ($infiniteScrollEvent) {
            ($infiniteScrollEvent.target).complete();
        }
        
	}

  /*useEffect(() => {
    axios.get('/establishments/nearby')
      .then(res => {
        const data = res.data;
        setEstablishments(data.establishments);
        setCategories(data.categories);
        const featured = data.establishments.filter((establishment) => establishment.featured === 1)
        setFeatured(featured);
        if (data.notifications > 0)
          setHasNotification(true)
        setLoad(true);
      })
      .catch(err => {
        setLoad(true)
      })
  }, []);*/


  return (
    <IonPage>

      {(load) && (<IonHeader className="bg-white ion-no-border border-bottom">
        <IonToolbar color="white">
					<IonButtons slot="primary">
						<IonButton onClick={() => setQuery('')}>
							<IonIcon icon={close} className="text-muted" />
						</IonButton>
            {auth.user && (<IonButton onClick={() => {setHasNotification(false); props.history.push('/notifications')}} className="position-relative">
              <IonIcon icon={notificationsOutline} className="text-default" />
              {hasNotification && (<span className="hint danger position-absolute"></span>)}
            </IonButton>)}
					</IonButtons>
					<IonButtons slot="start">
						<IonButton>
							<IonIcon icon={search} className="text-muted" />
						</IonButton>
					</IonButtons>

					<IonInput debounce={1500} onIonChange={onSearch} value={query} placeholder="Pesquisar Produtos" className="font-weight-bold text-default">
					</IonInput>
				</IonToolbar>
      </IonHeader>)}


      <IonContent fullscreen
                scrollEvents={true} onIonScrollStart={(e) => { setHide(true) }} 
                onClick={() => refreshError()}>
                    <IonToast
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message={msgToast}
                        duration={3000}
                    />
                    {details.id && !query && (
                        <div id="shop-header" className="shop-header">
                            <Row className="shop-content mx-0">
                                <Row className="mx-0 p-3 w-100">
                                    <h3 className="my-0 font-weight-light text-white text-left w-100">{details.name}</h3>
                                    <h6 className="m-0 text-left">
                                        <small className="mx-1 text-white">{details.email}</small>
                                    </h6>
                                </Row>
                            </Row>
                        </div>
                    )}
                    {status.error != '' && productsList.length == 0  ? (
                        <div className="text-center px-3 pt-3">
                            <p className="m-0 text-muted" style={{verticalAlign: 'midle'}}>Toque para tentar novamente <IonIcon icon={refreshOutline} /></p>
                            
                        </div>
                    ) : (
                        <Container className="p-0">
                            { (load && !query) && <Container fluid={true} className="my-2 p-0">
                            <h5 className="font-weight-bold px-3">Categorias</h5>
                            <Row className="my-2">
                                <Col lg="5">
                                {load && (
                                    (categories.length > 0) && <Categories history={props.history} categories={categories} />
                                )}
                                </Col>
                            </Row>
                            </Container>}
                            {(featuredProducts.length > 0 && !query) && (<Container fluid={true} className="my-3 p-0">
                            <h5 className="font-weight-bold px-3">Produtos em Destaque</h5>
                            <Row className="my-2">
                                <Col lg="5">
                                    {load ? (
                                        (featuredProducts.length > 0) ? <FeaturedMenu menuList={featuredProducts} establishment_id={details.id} />
                                            : (
                                                <div className="bg-white shadow-sm py-4 m-3 text-center">
                                                Nenhum produto em destaque disponível
                                                </div>
                                            )
                                    ) : <FeaturedSkeletonText />}
                                </Col>
                            </Row>
                        </Container>)}

                        <Container fluid={true} className="mt-3">
                            { load && <h5 className="font-weight-bold">Produtos</h5> }
                            <Row>
                                <Col lg="5">
                                    {load && !status.searching ? (
                                        (productsList.length > 0) ? <MenuList menuList={productsList} establishment_id={details.id} />
                                            : (
                                                <div className="bg-white shadow-sm py-4 my-2 w-100 text-center">
                                                    Nenhum produto disponível
                                                </div>
                                            )
                                    ) : [...Array(4)].map((e, i) => (<GeneralSkeletonText key={i} />))}
                                </Col>
                            </Row>
                        </Container>

                        {
                            (load && details.id === basket_establishment_id && basket.count > 0) && (
                                <Link className="row fixed-bottom w-100 bg-primary p-3 m-0 text-white" to="/cart">
                                    <Col xs="8" className="p-0">
                                        {basket.count} {basket.count == 1 ? 'Item' : 'Itens'} |  {currency_symbol} {basket.total}
                                    </Col>
                                    <Col className="text-right p-0">
                                        Carrinho
                                        <IonIcon icon={cart} className="ml-2 align-text-top text-white" />
                                    </Col>
                                </Link>
                            )
                        }

                        <Addons />
                    {(disableInfiniteScroll && <div className="pb-3"></div>)}
                    <IonInfiniteScroll className="pb-3" threshold="100px"
                        disabled={disableInfiniteScroll}
                        onIonInfinite={(e: CustomEvent<void>) => onSearch(null, e)}>
                        <IonInfiniteScrollContent
                            loadingText="">
                        </IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                        </Container>
                    )
                    }
                    

            </IonContent>


    </IonPage>
  );
};

export default Home;

