import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { cartOutline, compassOutline, personOutline, receiptOutline, searchOutline } from 'ionicons/icons';
import React from 'react';
import { RootStateOrAny } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import PrivateRoute, { useSelector } from '../../helpers/privateRoutes';
import Home from './Home';
import Orders from './Orders';
import Profile from './Profile';
import Search from './Search';

interface RouteProps {
  match?: any,
}

const DashboardIndex: React.FC<RouteProps> = ({ match }) => { 
  
  const authState = useSelector((state: RootStateOrAny) => state.auth)
  
  return (
  <IonTabs>
    <IonRouterOutlet>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/home`} />
      <Route path={`${match.url}/home`} component={Home} exact={true} />
      <Route path={`${match.url}/search`} component={Search} exact={true} />
      <Route path={`${match.url}/orders`} component={Orders} exact={true} />
      <Route path={`${match.url}/profile`} component={Profile} />
    </IonRouterOutlet>
    <IonTabBar slot="bottom">
      <IonTabButton tab="home" href={`${match.url}/home`}>
        <IonIcon icon={cartOutline} />
        <IonLabel>Produtos</IonLabel>
      </IonTabButton>
      <IonTabButton tab="orders" href={`${match.url}/orders`}>
        <IonIcon icon={receiptOutline} />
        <IonLabel>Pedidos</IonLabel>
      </IonTabButton>
      <IonTabButton tab="profile" href={`${match.url}/profile`}>
        <IonIcon icon={personOutline} />
        <IonLabel>Perfil</IonLabel>
      </IonTabButton>
    </IonTabBar>
  </IonTabs>
)};

export default DashboardIndex;
