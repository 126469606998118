import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { refreshOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import OrderItems from '../../components/MenuCards/order-items';
import GeneralSkeletonText from '../../components/skeleton_text/general_establishment';
import axios from '../../helpers/axiosInterceptor';
import { useSelector, RootStateOrAny } from 'react-redux';
import { Link } from 'react-router-dom';


const Orders: React.FC = (props: any) => {

  const [orderList, setOrderList] = useState([])
  const [load, setLoad] = useState(false)
  const currency_symbol = useSelector((state: RootStateOrAny) => state.auth.currency_symbol);
  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const [isAuth, setIsAuth] = useState(false);


  useIonViewWillEnter(() => {
    init()
    
  }, [auth]);

  const init = ()=> {
    setLoad(false);
    // Verificar autenticação
    let verify = false;
    if (auth.user) {
      verify = true;
    } else {
      // Local Storage
      const storage = JSON.parse(localStorage.getItem('persist:storage') as any)
      if (storage) {
        if (JSON.parse(storage.auth).auth_token) {
          verify = true;
        }
      }
    }

    if (!verify) {
      setLoad(true);
    } else {
      axios.get(`/orders`)
        .then(res => {
          const data = res.data
          setOrderList(data)
          
          setLoad(true);

          // setTimeout(() => {
          //   setLoad(false)
          // }, 30 * 1000)
        })
        .catch(err => {
          console.log(err)
        })
    }

    setIsAuth(verify);
  } 


  return (
    <IonPage>

      <IonHeader className="bg-white ion-no-border border-bottom">
        <IonToolbar color="white">
          {isAuth && <IonButtons slot="primary">
            <IonButton onClick={() => init()} className="position-relative">
              <IonIcon icon={refreshOutline} className="text-muted" />
              {/* <span className="hint danger position-absolute"></span> */}
            </IonButton>
          </IonButtons>}
          <IonTitle className="font-weight-bold text-dark">
            Meus Pedidos
          </IonTitle>
        </IonToolbar>
      </IonHeader>


      <IonContent fullscreen>

        <Container fluid={true} className="h-100">
          {/* <h5 className="font-weight-bold">Ongoing Orders</h5> */}
          {!isAuth ? (load && 
            (<Container className="text-center d-flex align-items-center h-100">
              <Col>
              
                <img src={require('../../assets/img/icons/common/user-login-black.png')} className="dull-filter mb-3" style={{height: '200px'}} alt="pan" />
                <h5 className="font-weight-bold">Necessesário Login</h5>
                <Link to="/auth/login">
                  <Button
                    block
                    className="my-1 rounded-pill"
                    color="dark"
                    size="lg">Realizar Login</Button>
                </Link>
                
                </Col>
            </Container>
            )
          ) : (
            <Row className="h-100">
              <Col>
                {load ? (
                  orderList.length === 0 ?
                    (
                      <Container fluid={true} className="h-100 d-flex align-items-center">
                        <Col>
                          {/* <h4 className="font-weight-light text-center text-muted">You have no orders</h4> */}
                          <img src={require('../../assets/img/icons/common/no-orders.png')} className="dull-filter p-5 w-100" alt="pan" />
                          {/* <h1><IonIcon icon={receiptOutline} /></h1> */}
                        </Col>
                      </Container>
                    )
                    :
                    orderList.map((data: any) => {
                      return (
                        <Card key={data.id} className="shadow-lg my-3">
                          <CardHeader data-event={data.id}>
                            <Row>
                              <Col data-event={data.id} className="p-0 text-dark font-weight-bold">
                                Pedido {data.unique_id}
                                <small className="d-block" data-event={data.id}>{data.created_at}</small>
                              </Col>
                              <Col xs="4" className="p-0 text-right" data-event={data.id}>
                                <Badge color="success" >{data.status}</Badge>
                              </Col>
                            </Row>
                          </CardHeader>
                          {/* <Collapse isOpen={collapsedOrder === index}> */}
                          <CardBody className="p-3">

                            <OrderItems order_products={data.order_products} />

                            <Row className="flex-row flex-wrap shadow-0 py-2 px-3 border-bottom">
                              <Col className="p-0">
                                <h6 className="my-0 item-name">Outras Cobranças</h6>
                              </Col>
                              <Col xs="3" className="p-0">
                                <span className="p-0 text-dark text-right d-block">{currency_symbol} {(parseInt(data.delivery_charge) + parseInt(data.establishment_charges) + parseInt(data.tax))}</span>
                              </Col>
                            </Row>
                            {data.coupon_discount !== '0' && (
                              <Row className="flex-row flex-wrap shadow-0 py-2 px-3 border-bottom">
                                <Col className="p-0">
                                  <h6 className="my-0 item-name text-success">Cupom de Desconto </h6>
                                </Col>
                                <Col xs="3" className="p-0">
                                  <span className="p-0 text-right d-block text-success">{currency_symbol} {data.coupon_discount}</span>
                                </Col>
                              </Row>
                            )}

                            <h6 className="text-right font-weight-bold">{currency_symbol} {data.total}</h6>
                          </CardBody>
                          {/* </Collapse> */}
                        </Card>
                      )
                    })
                ) : [...Array(5)].map((e, i) => (<Container className="mt-3 p-0" key={i}><GeneralSkeletonText /></Container>))}
              </Col>
            </Row>
          )
          }
          
          
        </Container>
      </IonContent>


    </IonPage>
  );
};

export default Orders;
