import { cartConstants } from '../helpers/constants';

const INITIAL_STATE = { establishment_id: '', products: [], coupon: {} };

export default function cart(state = INITIAL_STATE, action) {
    switch (action.type) {

        case cartConstants.ITEM_INCREMENT: {
            let products: any = state.products ?? [];
            const index = products.findIndex((product: any) => product.id === action.payload.product_id);

            if (state.establishment_id !== action.payload.establishment_id)
                products = []

            if (index === -1 || (action.payload.addons && action.payload.addons.length > 0)) {
                products.push({
                    count: 1,
                    addons_product: action.payload.addons ?? [],
                    price: action.payload.amount,
                    is_veg: action.payload.is_veg,
                    name: action.payload.name,
                    id: action.payload.product_id
                });
            } else
                products[index].count++;

            return {
                ...state,
                establishment_id: action.payload.establishment_id,
                products
            };
        }

        case cartConstants.ITEM_DECREMENT: {
            let products: any = state.products;
            const index = products.findIndex((product: any) => product.id === action.payload.product_id);

            if (index !== -1) {
                if (products[index].count === 1)
                    products.splice(index, 1);
                else
                    products[index].count--;
            }
            return {
                ...state,
                establishment_id: action.payload.establishment_id,
                products
            };
        }

        case cartConstants.ADDONS_SHOW: {
            return {
                ...state,
                addons: action.payload
            };
        }

        case cartConstants.ADDONS_HIDE: {
            return {
                ...state,
                addons: []
            };
        }

        case cartConstants.COUPON_ADD: {
            return {
                ...state,
                coupon: action.payload
            };
        }

        case cartConstants.COUPON_REMOVE: {
            return {
                ...state,
                coupon: {}
            };
        }

        case cartConstants.GRAND_TOTAL: {
            return {
                ...state,
                total: action.payload
            };
        }

        case cartConstants.ORDER_SUCCESS: {
            return INITIAL_STATE;
        }
        default:
            return state
    }
}
