export const apiConstants = {
    baseUrl: process.env.NODE_ENV === 'production' ? 'https://sistema.starten.global/api' : 'https://sistema.starten.global/api',
}

export const externalConstants = {
    google_map_api_key: '',
    PAYPAL_CLIENT_ID: '',
    RAZORPAY_ID: '',
    STRIPE_ID: '',
}

export const authConstants = {
    SET_CURRENCY: 'SET_CURRENCY',
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR',
    LOGOUT: 'USERS_LOGOUT',
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    VERIFY_REQUEST: 'USERS_VERIFY_REQUEST',
    VERIFY_SUCCESS: 'USERS_VERIFY_SUCCESS',
    VERIFY_FAILURE: 'USERS_VERIFY_FAILURE',

    UPDATE_AVATAR: 'UPDATE_AVATAR',

    SET_DEFAULT_ADDRESS: 'SET_DEFAULT_ADDRESS',

    SET_FCM_TOKEN: 'SET_FCM_TOKEN',
};

export const cartConstants = {
    ITEM_INCREMENT: 'ITEM_INCREMENT',
    ITEM_DECREMENT: 'ITEM_DECREMENT',
    
    ADDONS_SHOW: 'ADDONS_SHOW',
    ADDONS_HIDE: 'ADDONS_HIDE',

    COUPON_ADD: 'COUPON_ADD',
    COUPON_REMOVE: 'COUPON_REMOVE',

    GRAND_TOTAL: 'GRAND_TOTAL',

    PLACE_ORDER: 'PLACE_ORDER',
    ORDER_SUCCESS: 'ORDER_SUCCESS'
};

export const alertConstants = {
    FULLPAGE_LOADER: 'FULLPAGE_LOADER',
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR',
    LOGOUT: 'USERS_LOGOUT',
};


export default {
    authConstants,
    apiConstants,
    cartConstants,
    externalConstants,
    alertConstants
}

export const countryList = [

    { label: 'Afeganistão', value: 'AF' },
    { label: 'Ilhas Aland', value: 'AX' },
    { label: 'Albânia', value: 'AL' },
    { label: 'Argélia', value: 'DZ' },
    { label: 'Samoa Americana', value: 'AS' },
    { label: 'Andorra', value: 'AD' },
    { label: 'Angola', value: 'AO' },
    { label: 'Anguilla', value: 'AI' },
    { label: 'Antarctica', value: 'AQ' },
    { label: 'Antígua e Barbuda', value: 'AG' },
    { label: 'Argentina', value: 'AR' },
    { label: 'Armênia', value: 'AM' },
    { label: 'Aruba', value: 'AW' },
    { label: 'Australia', value: 'AU' },
    { label: 'Áustria', value: 'AT' },
    { label: 'Azerbaijão', value: 'AZ' },
    { label: 'Bahamas', value: 'BS' },
    { label: 'Bahrein', value: 'BH' },
    { label: 'Bangladesh', value: 'BD' },
    { label: 'Barbados', value: 'BB' },
    { label: 'Bélgica', value: 'BE' },
    { label: 'Belice', value: 'BZ' },
    { label: 'Benim', value: 'BJ' },
    { label: 'Bermudas', value: 'BM' },
    { label: 'Butão', value: 'BT' },
    { label: 'Bolívia', value: 'BO' },
    { label: 'Bonaire, Santo Eustáquio e Saba', value: 'BQ' },
    { label: 'Bósnia e Herzegovina', value: 'BA' },
    { label: 'Botsuana', value: 'BW' },
    { label: 'Ilha Bouvet', value: 'BV' },
    { label: 'Brasil', value: 'BR' },
    { label: 'Território Britânico do Oceano Índico', value: 'IO' },
    { label: 'Brunei Darussalam', value: 'BN' },
    { label: 'Bulgária', value: 'BG' },
    { label: 'Burquina Faso', value: 'BF' },
    { label: 'Burundi', value: 'BI' },
    { label: 'Cambodja', value: 'KH' },
    { label: 'Camarões', value: 'CM' },
    { label: 'Canada', value: 'CA' },
    { label: 'Cabo Verde', value: 'CV' },
    { label: 'Ilhas Cayman', value: 'KY' },
    { label: 'República Centro-Africana', value: 'CF' },
    { label: 'Chade', value: 'TD' },
    { label: 'Chile', value: 'CL' },
    { label: 'China', value: 'CN' },
    { label: 'Ilha do Natal', value: 'CX' },
    { label: 'Ilhas Cocos (Keeling)', value: 'CC' },
    { label: 'Colômbia', value: 'CO' },
    { label: 'Comores', value: 'KM' },
    { label: 'Congo', value: 'CG' },
    { label: 'Ilhas Cook', value: 'CK' },
    { label: 'Costa Rica', value: 'CR' },
    { label: 'Croácia', value: 'HR' },
    { label: 'Curacao', value: 'CW' },
    { label: 'Chipre', value: 'CY' },
    { label: 'República Checa', value: 'CZ' },
    { label: 'Danmark', value: 'DK' },
    { label: 'Djibouti', value: 'DJ' },
    { label: 'Dominica', value: 'DM' },
    { label: 'República Dominicana', value: 'DO' },
    { label: 'Equador', value: 'EC' },
    { label: 'Egito', value: 'EG' },
    { label: 'El Salvador', value: 'SV' },
    { label: 'Guiné Equatorial', value: 'GQ' },
    { label: 'Eritreia', value: 'ER' },
    { label: 'Estônia', value: 'EE' },
    { label: 'Etiópia', value: 'ET' },
    { label: 'Ilhas Falkland (Malvinas)', value: 'FK' },
    { label: 'Ilhas Faroe', value: 'FO' },
    { label: 'Fiji', value: 'FJ' },
    { label: 'Finlândia', value: 'FI' },
    { label: 'França', value: 'FR' },
    { label: 'Guiana Francesa', value: 'GF' },
    { label: 'Polinésia Francesa', value: 'PF' },
    { label: 'Territórios Franceses do Sul', value: 'TF' },
    { label: 'Gabão', value: 'GA' },
    { label: 'Gâmbia', value: 'GM' },
    { label: 'Georgia', value: 'GE' },
    { label: 'Alemanha', value: 'DE' },
    { label: 'Gana', value: 'GH' },
    { label: 'Gibraltar', value: 'GI' },
    { label: 'Grécia', value: 'GR' },
    { label: 'Groenlândia', value: 'GL' },
    { label: 'Granada', value: 'GD' },
    { label: 'Guadalupe', value: 'GP' },
    { label: 'Guam', value: 'GU' },
    { label: 'Guatemala', value: 'GT' },
    { label: 'Guernsey', value: 'GG' },
    { label: 'Guiné', value: 'GN' },
    { label: 'Guiné-Bissau', value: 'GW' },
    { label: 'Guiana', value: 'GY' },
    { label: 'Haiti', value: 'HT' },
    { label: 'Ilha Heard e Ilhas Mcdonald', value: 'HM' },
    { label: 'Santa Sé (Cidade do Vaticano)', value: 'VA' },
    { label: 'Honduras', value: 'HN' },
    { label: 'Hong Kong', value: 'HK' },
    { label: 'Hungria', value: 'HU' },
    { label: 'Islândia', value: 'IS' },
    { label: 'Índia', value: 'IN' },
    { label: 'Indonésia', value: 'ID' },
    { label: 'Iraque', value: 'IQ' },
    { label: 'Irlanda', value: 'IE' },
    { label: 'Ilha de Man', value: 'IM' },
    { label: 'Israel', value: 'IL' },
    { label: 'Itália', value: 'IT' },
    { label: 'Jamaica', value: 'JM' },
    { label: 'Japão', value: 'JP' },
    { label: 'Camisola', value: 'JE' },
    { label: 'Jordânia', value: 'JO' },
    { label: 'Cazaquistão', value: 'KZ' },
    { label: 'Quênia', value: 'KE' },
    { label: 'Quiribati', value: 'KI' },
    { label: 'Republica da Coréia', value: 'KR' },
    { label: 'Kuwait', value: 'KW' },
    { label: 'Quirguistão', value: 'KG' },
    { label: 'Laos, República Democrática', value: 'LA' },
    { label: 'Letônia', value: 'LV' },
    { label: 'Líbano', value: 'LB' },
    { label: 'Lesoto', value: 'LS' },
    { label: 'Libéria', value: 'LR' },
    { label: 'Líbia', value: 'LY' },
    { label: 'Liechtenstein', value: 'LI' },
    { label: 'Lituânia', value: 'LT' },
    { label: 'Luxemburgo', value: 'LU' },
    { label: 'Macau', value: 'MO' },
    { label: 'Madagascar', value: 'MG' },
    { label: 'Malaui', value: 'MW' },
    { label: 'Malaysia', value: 'MY' },
    { label: 'Maldivas', value: 'MV' },
    { label: 'Mali', value: 'ML' },
    { label: 'Malta', value: 'MT' },
    { label: 'Ilhas Marshall', value: 'MH' },
    { label: 'Martinique', value: 'MQ' },
    { label: 'Mauritânia', value: 'MR' },
    { label: 'Maurício', value: 'MU' },
    { label: 'Mayotte', value: 'YT' },
    { label: 'México', value: 'MX' },
    { label: 'Micronésia', value: 'FM' },
    { label: 'Moldávia', value: 'MD' },
    { label: 'Monaco', value: 'MC' },
    { label: 'Mongólia', value: 'MN' },
    { label: 'Montenegro', value: 'ME' },
    { label: 'Montserrat', value: 'MS' },
    { label: 'Marrocos', value: 'MA' },
    { label: 'Moçambique', value: 'MZ' },
    { label: 'Myanmar', value: 'MM' },
    { label: 'Namíbia', value: 'NA' },
    { label: 'Nauru', value: 'NR' },
    { label: 'Nepal', value: 'NP' },
    { label: 'Nederland', value: 'NL' },
    { label: 'Nova Caledônia', value: 'NC' },
    { label: 'Nova Zelândia', value: 'NZ' },
    { label: 'Nicarágua', value: 'NI' },
    { label: 'Níger', value: 'NE' },
    { label: 'Nigéria', value: 'NG' },
    { label: 'Niue', value: 'NU' },
    { label: 'Ilha Norfolk', value: 'NF' },
    { label: 'Macedônia do Norte', value: 'MK' },
    { label: 'Ilhas Marianas do Norte', value: 'MP' },
    { label: 'Noruega', value: 'NO' },
    { label: 'Omã', value: 'OM' },
    { label: 'Paquistão', value: 'PK' },
    { label: 'Palau', value: 'PW' },
    { label: 'Palestina', value: 'PS' },
    { label: 'Panamá', value: 'PA' },
    { label: 'Papua Nova Guiné', value: 'PG' },
    { label: 'Paraguai', value: 'PY' },
    { label: 'Peru', value: 'PE' },
    { label: 'Filipinas', value: 'PH' },
    { label: 'Pitcairn', value: 'PN' },
    { label: 'Polônia', value: 'PL' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Porto Rico', value: 'PR' },
    { label: 'Qatar', value: 'QA' },
    { label: 'Reunião', value: 'RE' },
    { label: 'Roménia', value: 'RO' },
    { label: 'Ruanda', value: 'RW' },
    { label: 'São Bartolomeu', value: 'BL' },
    { label: 'Santa Helena, Ascensão e Tristão da Cunha', value: 'SH' },
    { label: 'São Cristóvão e Nevis', value: 'KN' },
    { label: 'Santa Lúcia', value: 'LC' },
    { label: 'Saint Martin', value: 'MF' },
    { label: 'Saint Pierre e Miquelon', value: 'PM' },
    { label: 'São Vicente e Granadinas', value: 'VC' },
    { label: 'Sanoa', value: 'WS' },
    { label: 'San Marino', value: 'SM' },
    { label: 'Arábia Saudita', value: 'SA' },
    { label: 'Senegal', value: 'SN' },
    { label: 'Sérvia', value: 'RS' },
    { label: 'Seychelles', value: 'SC' },
    { label: 'Serra Leoa', value: 'SL' },
    { label: 'Singapore', value: 'SG' },
    { label: 'Sint Maarten', value: 'SX' },
    { label: 'Eslováquia', value: 'SK' },
    { label: 'Eslovenia', value: 'SI' },
    { label: 'Ilhas Salomão', value: 'SB' },
    { label: 'Somália', value: 'SO' },
    { label: 'África do Sul', value: 'ZA' },
    { label: 'Geórgia do Sul e Ilhas Sandwich do Sul', value: 'GS' },
    { label: 'Sudão do Sul', value: 'SS' },
    { label: 'Espanha', value: 'ES' },
    { label: 'Sri Lanka', value: 'LK' },
    { label: 'Suriname', value: 'SR' },
    { label: 'Svalbard e Jan Mayen', value: 'SJ' },
    { label: 'Suécia', value: 'SE' },
    { label: 'Suíça', value: 'CH' },
    { label: 'São Tomé e Príncipe', value: 'ST' },
    { label: 'Taiwan', value: 'TW' },
    { label: 'Tajiquistão', value: 'TJ' },
    { label: 'Tanzânia', value: 'TZ' },
    { label: 'Tailândia', value: 'TH' },
    { label: 'Togo', value: 'TG' },
    { label: 'Tokelau', value: 'TK' },
    { label: 'Tonga', value: 'TO' },
    { label: 'Trinidade e Tobago', value: 'TT' },
    { label: 'Tunísia', value: 'TN' },
    { label: 'Turquia', value: 'TR' },
    { label: 'Turquemenistão', value: 'TM' },
    { label: 'Ilhas Turks e Caicos', value: 'TC' },
    { label: 'Tuvalu', value: 'TV' },
    { label: 'Uganda', value: 'UG' },
    { label: 'Ucrânia', value: 'UA' },
    { label: 'Emirados Árabes Unidos', value: 'AE' },
    { label: 'Reino Unido', value: 'GB' },
    { label: 'Estados Unidos', value: 'US' },
    { label: 'Estados Unidos Ilhas Menores Distantes', value: 'UM' },
    { label: 'Uruguai', value: 'UY' },
    { label: 'Uzbequistão', value: 'UZ' },
    { label: 'Vanuatu', value: 'VU' },
    { label: 'Venezuela', value: 'VE' },
    { label: 'Vietnã', value: 'VN' },
    { label: 'Ilhas Virgens Britânicas', value: 'VG' },
    { label: 'Ilhas Virgens, EUA', value: 'VI' },
    { label: 'Wallis e Futuna', value: 'WF' },
    { label: 'Saara Ocidental', value: 'EH' },
    { label: 'Iêmen', value: 'YE' },
    { label: 'Zâmbia', value: 'ZM' },
    { label: 'Zimbábue', value: 'ZW' },
].sort((a,b) => {
    return a.label.localeCompare(b.label);
});