import React, { useState, useEffect } from 'react';
import Card from "react-credit-cards";
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import actions from '../../actions/cart';
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate
  } from "../../helpers/cardUtils";

const CreditCard: React.FC = (props: any) => {
    const cart = useSelector((state: RootStateOrAny) => state.cart);
    let [cardValue, setCardValue] = useState({
        number: '',
        name: '',
        expiry: '',
        cvc: '',
        issuer: '',
        focused: '',
        isValid: false
    });

    useEffect(() => {
  
      // Cartão de Crédito
      let cartForm = cart;
      cartForm.creditCard = cardValue;
      dispatch(actions.place_order_add(cartForm, props.history));
    }, [])

    const dispatch = useDispatch();

    const handleCallback = ({ issuer }, isValid) => {
        let cartForm = cart;
        let newValue = {...cardValue};
        if (isValid) {
          newValue = { ...cardValue, issuer, isValid: true};
        } else {
          newValue = { ...cardValue, issuer, isValid: false};
        }
        setCardValue(newValue);
        cartForm.creditCard = newValue;
        dispatch(actions.place_order_add(cartForm, props.history));
      };
    
      const handleInputFocus = ({ target }) => {
        setCardValue({ ...cardValue, focused: target.name});
      };
    
      const handleInputChange = ({ target }) => {
        if (target.name === "number") {
          target.value = formatCreditCardNumber(target.value);
        } else if (target.name === "expiry") {
          target.value = formatExpirationDate(target.value);
        } else if (target.name === "cvc") {
          target.value = formatCVC(target.value);
        }

        let cartForm = cart;
        cartForm.creditCard = { ...cardValue, [target.name]: target.value};
        dispatch(actions.place_order_add(cartForm, props.history));

        setCardValue({ ...cardValue, [target.name]: target.value});

        
      };
    
      const handleSubmit = e => {
        e.preventDefault();
        const { issuer } = cardValue;
        const formData = [...e.target.elements]
          .filter(d => d.name)
          .reduce((acc, d) => {
            acc[d.name] = d.value;
            return acc;
          }, {});
        setCardValue(formData);
        //form.reset();
      };
  return (
    <div className="App-cards mt-5">
      <Card
      number={cardValue.number}
      name={cardValue.name}
      expiry={cardValue.expiry}
      cvc={cardValue.cvc}
      focused={cardValue.focused}
      callback={handleCallback}
      locale={{
        valid: 'Validade'
      }}
      placeholders={{
        name: 'Nome do Titular'
      }}
      />
      <form onSubmit={handleSubmit}>
      <div className="form-group  mt-5">
          <input
          type="tel"
          name="number"
          className="form-control"
          placeholder="Número do Cartão"
          pattern="[\d| ]{16,22}"
          required
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          />
      </div>
      <div className="form-group">
          <input
          type="text"
          name="name"
          className="form-control"
          placeholder="Nome do Titular"
          required
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          />
      </div>
      <div className="row">
          <div className="col-6">
          <input
              type="tel"
              name="expiry"
              className="form-control"
              placeholder="Validade MM/YY"
              pattern="\d\d/\d\d"
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
          />
          </div>
          <div className="col-6">
          <input
              type="tel"
              name="cvc"
              className="form-control"
              placeholder="Código de Segurança"
              pattern="\d{3,4}"
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
          />
          </div>
      </div>
      <input type="hidden" name="issuer" value={cardValue.issuer} />
      </form>
    </div>
  );
}

export default CreditCard;
